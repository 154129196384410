import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export const Breadcrumb = ({ breadcrumb, title, description, pageType }) => {
  const router = useRouter()

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div
        style={{
          // backgroundImage: `${pageType !== "detail" ? "url(/assets/img/jus_banner.webp)" : ""}`,
          paddingBottom: pageType === 'detail' ? '0px' : '',
          paddingTop: pageType === 'detail' ? '10px' : '',
        }}
        className='detail-block detail-block_margin'
      >
        <div
          className='wrapper'
          style={{ paddingTop: pageType === 'detail' ? '20px' : '0' }}
        >
          <div className='detail-block__content'>
            <h1
              className='bread-crumb bread-title'
              style={{
                background:
                  router.pathname === '/partyPlay' ||
                  router.pathname === '/embrace_toy_rental' ||
                  router.pathname === '/how_it_work' ||
                  router.pathname === '/basic' ||
                  router.pathname === '/premium' ||
                  router.pathname === '/standard' ||
                  router.pathname === '/subscriptionSearch' ||
                  router.pathname === '/subscriptionCart'
                    ? '#faecf3'
                    : 'transparent',
                borderRadius: '10px',
              }}
            >
              {title}
            </h1>

            {breadcrumb && (
              <ul
                className='bread-crumbs'
                style={{
                  float: pageType === 'detail' ? 'left' : 'center',
                  paddingTop:
                    pageType === 'category' ||
                    pageType === 'subscriptionSearch' ||
                    pageType === 'subscription'
                      ? '10px'
                      : '',
                  paddingBottom:
                    pageType === 'category' ||
                    pageType === 'subscriptionSearch' ||
                    pageType === 'subscription'
                      ? '10px'
                      : '',
                }}
              >
                {breadcrumb?.map(({ path, label }, i) => {
                  return (
                    <React.Fragment key={i}>
                      {path === router.asPath ? (
                        <li
                          style={{
                            margin: '15px',
                            backgroundColor:
                              router.pathname === '/embrace_toy_rental'
                                ? '#faecf3'
                                : 'transparent',
                            borderRadius: '5px',
                          }}
                          className='bread-crumbs__item'
                        >
                          {label}
                        </li>
                      ) : (
                        <li className='bread-crumbs__item'>
                          <Link href={path}>
                            <a
                              className='bread-crumbs__link'
                              style={{
                                backgroundColor:
                                  router.pathname === '/embrace_toy_rental'
                                    ? '#faecf3'
                                    : 'transparent',
                              }}
                            >
                              {label}
                            </a>
                          </Link>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
            )}

            {router.pathname === '/partyPlay' && (
              <div
                className='breadcrumbStyle'
                style={{
                  letterSpacing: '2px',

                  fontWeight: '800',
                  fontSize: '25px',
                }}
              >
                <span
                  style={{
                    letterSpacing: '2px',
                    fontWeight: '600',
                    fontSize: '25px',
                    background: '#faecf3',
                    fontFamily: 'Nunito, sans-serif',
                    borderRadius: '5px',
                    color: '#b86e93',
                    marginBottom: '6px',
                  }}
                >
                  Get the party started on our Instagram feed!
                </span>
                <a
                  className='anchor'
                  style={{
                    backgroundColor: '#faecf3',

                    borderRadius: '5px',
                    fontWeight: '700',
                    textDecoration: 'underline',
                    textDecorationColor: '#b86e93',
                  }}
                  href='https://www.instagram.com/jusplay_toyrental'
                  target='_blank'
                >
                  Click here
                </a>
                <span
                  style={{
                    letterSpacing: '2px',
                    fontWeight: '600',
                    fontSize: '25px',
                    background: '#faecf3',
                    marginBottom: '5px',
                    fontFamily: 'Nunito, sans-serif',
                    borderRadius: '5px',
                    color: '#b86e93',
                  }}
                >
                  for a peek into the fun
                </span>
              </div>
            )}
            {router.pathname === '/gallery' && (
              <div
                className='breadcrumbStyle'
                style={{
                  letterSpacing: '2px',
                  fontWeight: '600',
                  fontSize: '25px',
                  background: '#faecf3',
                  padding: '5px',
                  fontFamily: 'Nunito, sans-serif',
                  borderRadius: '5px',
                  color: '#b86e93',
                }}
              >
                <span>Dive into our toy world on Instagram!</span>

                <a
                  className='anchor'
                  style={{
                    backgroundColor: '#faecf3',
                    padding: '1px',
                    borderRadius: '5px',
                    fontWeight: '800',
                    textDecoration: 'underline',
                  }}
                  href='https://www.instagram.com/jusplay_toyrental'
                  target='_blank'
                >
                  Click here
                </a>
                <span>for playtime magic 🚀📷</span>
              </div>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  )
}

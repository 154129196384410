import React from "react";
import { whyUs } from "../../../../constants/whyUs";

const WhyUs = () => {
  return (
    <>
      <h4 className="gradient-title why-us-title">Why Choose Jusplay?</h4>
      <div className="why-us-wrapper">
        {whyUs.map((item, i) => (
          <div className="why-us" key={i}>
            <div>{item.icon}</div>
            <h6>{item.title}</h6>
            <p className="why-us-desc">{item.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default WhyUs;

import client from '../../../client'

export const replaceURL = (url) => {
  return url?.replace(
    'https://jusplaytoys.fra1.digitaloceanspaces.com',
    'https://main-object-storage.blr1.cdn.digitaloceanspaces.com'
  )
}

export const sanityPagination = async (
  query,
  itemsPerPage = 18,
  currentPage = 1,
  order = 'desc' // pass desc or asc
) => {
  const startIdx = itemsPerPage * (currentPage - 1)
  const endIdx = startIdx + (itemsPerPage - 1)
  console.log(
    'sanity Query',
    `${query} | order(_createdAt ${order})[${startIdx}..${endIdx}]`
  )
  const data = await client.fetch(
    `${query} | order(_createdAt ${order})[${startIdx}..${endIdx}]`
  )
  return data
}
